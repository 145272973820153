<!--  -->
<template>
  <div>
    <!-- 盘点类型 -->
    <div class="point_top">
      <i class="el-icon-arrow-left" @click="back()"></i>
      <div class="point_kind">{{ detailsList.inventoryName }}</div>
      <div class="point_point">…</div>
    </div>
    <!-- 盘点差异 -->
    <div style="font-size: 2vw;margin-left: 3%;margin-top: 1vw;font-size: 3vw;" v-if="itemList.length > 0" >
      <table border="1" cellspacing="0" width="96%">
        <tr>
          <td width="14%" align="center">货号</td>
          <td width="10%" align="center" v-if="sign == 1">批号</td>
          <td width="14.2%" align="center">商品名称</td>
          <td width="14.2%" align="center">生产单位</td>
          <td width="16%" align="center">账面数量</td>
          <td width="16%" align="center">实盘数量</td>
          <td width="16%" align="center">差异数量</td>
        </tr>
        <tr v-for="(item, index) in itemList" :key="index">
          <td width="10%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.itemNo }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 9vw;"  >{{ item.itemNo }}</div>
            </el-tooltip>
          
          </td>
          <td width="10%" align="center" v-if="sign == 1">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.batchNo }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 7vw;"  >{{ item.batchNo }}</div>
            </el-tooltip>
            
          </td>
          <td width="14.2%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.commonName }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 13vw;"  >{{ item.commonName }}</div>
            </el-tooltip>
            
           </td>
          <td width="14.2%" align="center">
            <el-tooltip placement="top">
                  <div slot="content">{{ item.origin }}</div>
                  <div  style=" overflow:hidden; white-space: nowrap;width: 13vw;"  >{{ item.origin }}</div>
            </el-tooltip>
          </td>
          <td width="16%%" align="center">{{ item.bookQuantity }}</td>
          <td width="16%%" align="center">{{ item.realQuantity }}</td>
          <td width="16%%" align="center">{{ item.differenceQuantity }}</td>
        </tr>
        
        <tr>
          <td width="10%" align="center">总计</td>
          <td width="10%" align="center" v-if="sign == 1"></td>
          <td width="14.2%" align="center"></td>
          <td width="14.2%" align="center"></td>
          <td width="16%" align="center">{{ bookQuantityNum }}</td>
          <td width="16%" align="center">{{ realQuantityNum }}</td>
          <td width="16%" align="center">{{ differenceQuantityNum }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [],
      detailsList: [],
      bookQuantityNum: "",
      realQuantityNum: "",
      differenceQuantityNum: "",
      sign: "",
    };
  },

  components: {},

  computed: {},
  mounted() {
    let id1 = this.$route.params.id;
    this.differ(id1);
    let id = this.$route.params.id;
    console.log(id,"11111111111111")
    this.detailList(id);
  },
  methods: {
    differ(id) {
      console.log(id, "djofslkkfffffffffffffffff");
      this.$axios
        .get(this.$api.differentList, {
          params: {
            iid: id,
          },
        })
        .then((res) => {
          console.log(res, "differentList");
          if (res.data.code === 0 || res.data.msg === "success") {
            console.log(res, "res");
            this.itemList = res.data.different;
            this.sign = res.data.sign;
            console.log(this.sign, "aaaa");
            console.log(this.itemList, "this.itemList");
            // if(res.data.differentdifferenceQuantity==null){
            //     res.data.differentdifferenceQuantity==""
            // }
            var s = 0;
            var m = 0;
            var n = 0;
            this.itemList.map((item) => {
              s += Number(item.bookQuantity);
              this.bookQuantityNum = s;
              m += Number(item.realQuantity);
              this.realQuantityNum = m;
              n += Number(item.differenceQuantity);
              this.differenceQuantityNum = n;
            });
          }
        });
    },
    // 详情list
    detailList(id) {
      this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/v1/inventory/inventoryInfo/${id}`,
          {}
        )
        .then((res) => {
          this.detailsList = res.data.inventory[0];

          console.log(res, "iwsdxsdasda");
          console.log(this.detailsList, "this.detailsList");
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang='less' scoped>
</style>
<style>
.point_top {
  width: 100%;
  height: 9vh;
  background-image: linear-gradient(#dfe3e5, #eef5f8);
  color: #443d3d;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_kind {
  font-weight: 700;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: bolder;
}
</style>